import { useState, useEffect } from 'react';
import { Alert, Form, Modal, Button } from 'react-bootstrap';

function EditAuthClientModal({ show, handleClose, titleId, authClient, onSuccessSave }) {
    const [clientSecret, setClientSecret] = useState('');
    const [clientName, setClientName] = useState('');
    const [redirectUris, setRedirectUris] = useState('');
    const [postLogoutRedirectUris, setPostLogoutRedirectUris] = useState('');
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (show && authClient) {
            setClientSecret(authClient.clientSecret);
            setClientName(authClient.clientName);
            setRedirectUris(authClient.redirectUris);
            setPostLogoutRedirectUris(authClient.postLogoutRedirectUris);
        }
    }, [show, authClient, titleId]);

    const handleSave = async() => {
        setSaving(true);
        setSaveError(null);

        const authClientPk = authClient.id.split('_')[1];
        const authClientData = {
            ...authClient,
            clientSecret: clientSecret,
            clientName: clientName,
            redirectUris: redirectUris,
            postLogoutRedirectUris: postLogoutRedirectUris,
        };
        try {
            const response = await fetch(`${API_URL}/v1/admin/authclients/${authClientPk}`, {
                method: 'PUT',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(authClientData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to save client configuration');
            }
            const updatedClient = await response.json();
            handleClose();
            onSuccessSave(updatedClient);
        } catch (error) {
            setSaveError(error.message);
        } finally {
            setSaving(false);
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit AuthClient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='auth-form'>
                    <Form.Group controlId="clientName">
                        <Form.Label>Client Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            placeholder="Enter client name"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="clientSecret">
                        <Form.Label>Client Secret</Form.Label>
                        <Form.Control
                            type="text"
                            value={clientSecret}
                            onChange={(e) => setClientSecret(e.target.value)}
                            placeholder="Enter client secret"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="redirectUris">
                        <Form.Label>Redirect URIs</Form.Label>
                        <Form.Control
                            type="text"
                            value={redirectUris}
                            onChange={(e) => setRedirectUris(e.target.value)}
                            placeholder="Enter redirect URIs, comma separated"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="postLogoutRedirectUris">
                        <Form.Label>Post Logout Redirect URIs</Form.Label>
                        <Form.Control
                            type="text"
                            value={postLogoutRedirectUris}
                            onChange={(e) => setPostLogoutRedirectUris(e.target.value)}
                            placeholder="Enter post logout redirect URIs, comma separated"
                            required
                        />
                    </Form.Group>
                </Form>
                {saveError && <Alert variant="danger" className="mt-3">{saveError}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditAuthClientModal;

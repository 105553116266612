import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { fetchTitleDetails, apiRequest } from '../utils/apiUtils';

const GameDetailsForm = ({ show, handleClose, title, titleId }) => {
    const [gameUrl, setGameUrl] = useState('');
    const [platforms, setPlatforms] = useState([]);
    const [tags, setTags] = useState([]);
    const [fullTitleDetails, setFullTitleDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const loadTitleDetails = async () => {
            setLoading(true);
            try {
                const data = await fetchTitleDetails(API_URL, csrfToken, titleId);
                setFullTitleDetails(data);
                setGameUrl(data.gameLaunchUrl || '');
                setPlatforms(data.platforms || []);
                setTags(data.tags || []); 
            } catch {
                setErrorMessage('Failed to fetch game details.');
            }
            finally {
                setLoading(false);
            }
        };

        if (show) {
            loadTitleDetails();
        }
    }, [show, titleId, API_URL, csrfToken]);

    const handleSaveGameUrl = async () => {
        setLoading(true);
        try {
            const updatedTitle = {
                ...fullTitleDetails,
                gameLaunchUrl: gameUrl,
                platforms,
                tags,
            };
            console.log(updatedTitle);

            await apiRequest(
                `${API_URL}/v1/admin/titles/${titleId}`,
                'PUT',
                csrfToken,
                updatedTitle
            );

            setSuccessMessage('Game URL updated successfully!');
            setTimeout(() => {
                setSuccessMessage(null);
                handleClose();
            }, 2000);
        } catch (error) {
            console.error('Error updating game URL:', error);
            setErrorMessage('Failed to update the game URL. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const toggleCheckbox = (value, setter, currentValues) => {
        if (currentValues.includes(value)) {
            setter(currentValues.filter((v) => v !== value));
        } else {
            setter([...currentValues, value]);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Update Game Details for {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {successMessage && (
                    <div className="alert alert-info" role="alert">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <Form>
                        <Form.Group controlId="gameUrl">
                            <Form.Label>Game Launch URL:</Form.Label>
                            <Form.Control
                                type="text"
                                value={gameUrl}
                                onChange={(e) => setGameUrl(e.target.value)} // Update state on input change
                                placeholder="Enter Game URL"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="platforms" className="mb-3">
                            <Form.Label>Platforms:</Form.Label>
                            {['MOBILE', 'STEAM', 'WINDOWS'].map((platform) => (
                                <Form.Check
                                    key={platform}
                                    type="checkbox"
                                    label={platform}
                                    checked={platforms.includes(platform)}
                                    onChange={() => toggleCheckbox(platform, setPlatforms, platforms)}
                                />
                            ))}
                        </Form.Group>

                        <Form.Group controlId="tags" className="mb-3">
                            <Form.Label>Tags:</Form.Label>
                            {['RECOMMENDED', 'FEATURED'].map((tag) => (
                                <Form.Check
                                    key={tag}
                                    type="checkbox"
                                    label={tag}
                                    checked={tags.includes(tag)}
                                    onChange={() => toggleCheckbox(tag, setTags, tags)}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveGameUrl} disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GameDetailsForm;

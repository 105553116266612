import { Form, Button } from 'react-bootstrap';
import './FileUploadField.scss';

const FileUploadField = ({ fieldName, label, description, files, existingImages = [], onFileChange, onRemoveFile, onRemoveExistingImage, deleting }) => {
    return (
        <>
            <div className="upload-container mb-3">
                <div className="icon-and-text">
                    <i className="bi bi-image" style={{ fontSize: '1.5rem', marginRight: '0.5rem', color: '#6c757d' }}></i>
                    <div>
                        <p className="mb-1">{label}</p>
                        <p className="text-muted small">{description}</p>
                    </div>
                </div>
                <Button variant="outline-dark" size="sm" as="label">
                    Browse
                    <Form.Control
                        type="file"
                        onChange={(e) => onFileChange(e, fieldName)}
                        hidden
                        multiple
                    />
                </Button>
            </div>

            {existingImages.length > 0 && (
                <div className="existing-images mt-3">
                    {existingImages.map((image, index) => (
                        <div key={index} className="file-preview">
                            <div className="file-info">
                                <i className="bi bi-file-earmark-image" style={{ fontSize: '1rem', color: '#fd7e14' }}></i>
                                <a
                                    href={image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="image-link"
                                >
                                    {image.split('/').pop()}
                                </a>
                            </div>
                            <Button
                                variant="outline-danger"
                                size="xs"
                                onClick={() => onRemoveExistingImage(index)}
                                disabled={deleting}
                            >
                                <i className="bi bi-trash"></i>
                            </Button>
                        </div>
                    ))}
                </div>
            )}

            {files.length > 0 && (
                <div className="uploaded-files mt-3">
                    {files.map((file, index) => (
                        <div key={index} className="file-preview">
                            <div className="file-info">
                                <i className="bi bi-file-earmark" style={{ fontSize: '1rem', color: '#fd7e14' }}></i>
                                <div>
                                    <p className="mb-0 image-text">{file.name}</p>
                                    <p className="text-muted small">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                                </div>
                            </div>
                            <Button
                                variant="outline-danger"
                                size="xs"
                                onClick={() => onRemoveFile(fieldName, index)}
                            >
                                <i className="bi bi-trash"></i>
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default FileUploadField;

import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react';

function Login() {
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    console.log(process.env.REACT_APP_API_URL);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch(`${API_URL}/api/v1/start`, {
                    method: 'GET',
                    credentials: 'include',
                });

                console.log('Response received');
                console.log('Response status: ' + response.status);

                if (!response.ok) {
                    console.log('Response is not okay, status:', response.status);

                    // Attempt to extract and parse the response body as JSON
                    try {
                        const data = await response.json();
                        console.log('Parsed JSON data:', data);

                        // Check if the response contains the login URL
                        if (data.message) {
                            window.location.href = API_URL + data.message;
                            return;
                        } else {
                            throw new Error('Login URL not found in response');
                        }
                    } catch (jsonError) {
                        console.error('Error parsing response as JSON:', jsonError);
                        throw new Error('Unexpected response format');
                    }
                }

                // If the response is okay, parse the JSON data
                const data = await response.json();
                console.log('Response JSON data:', data);

                const csrfToken = data.csrfToken;

                // Store the CSRF token for other requests
                sessionStorage.setItem('csrfToken', csrfToken);

                // Navigate to the Titles page
                navigate('/titles');
            } catch (error) {
                console.error('Error in handleLogin:', error);
                alert('Login failed. Please try again.');
                throw error; // Rethrow to allow error handling by the caller
            }
        }

        fetchCsrfToken();
    }, []);

    return <div></div>
}

export default Login;

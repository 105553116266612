export const apiRequest = async (url, method, csrfToken, body = null) => {
    const headers = {
        'X-XSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
    };

    const options = {
        method,
        headers,
        credentials: 'include',
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to process the request');
    }
    return await response.json();
};


export const fetchTitleDetails = async(API_URL, csrfToken, titleId) => {
    try {
        const response = await fetch(`${API_URL}/v1/admin/titles/${titleId}`, {
            headers: {
                'X-XSRF-TOKEN': csrfToken,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch title details: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching title details:", error);
        throw error;
    }
}

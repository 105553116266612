import { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import FileUploadField from '../FileUploadField/FileUploadField';
import { fetchTitleDetails } from '../../utils/apiUtils';

const UploadImagesModal = ({
    show,
    handleClose,
    title,
    titleId,
    apiEndpoint,
    modalTitle,
    uploadFieldName,
    uploadFieldDescription,
    imagesKey
}) => {
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState({});
    const [existingImages, setExistingImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [deleting, setDeleting] = useState(false);

    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            try {
                const data = await fetchTitleDetails(API_URL, csrfToken, titleId);
                console.log(data['gameCardImages'])
                //setExistingImages(data[imagesKey] || []);
                const uniqueImages = [...new Set(data[imagesKey] || [])]; // Remove duplicates
                setExistingImages(uniqueImages);
                console.log('API Data:', data[imagesKey]);
                console.log('Existing Images:', existingImages);
            }
            catch (err) {
                console.log("Image fetch error");
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchImages();
        }
    }, [show, titleId, API_URL, csrfToken, imagesKey]);

    const handleFileChange = (e, field) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prev) => ({
            ...prev,
            [field]: [...(prev[field] || []), ...newFiles],
        }));
    };

    const handleRemoveFile = async (field, index, isExisting = false) => {
        console.log(field);
        if (isExisting) {
            const imageUrl = existingImages[index];
            console.log(imageUrl);
            if (deleting) return;
            setDeleting(true);
            try {
                const response = await fetch(`${API_URL}${apiEndpoint}/${titleId}?imageUrl=${encodeURIComponent(imageUrl)}`, {
                    method: "DELETE",
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to delete image: ${response.statusText}`);
                }
                setExistingImages((prev) => prev.filter((_, i) => i !== index));
                setSuccessMessage(`Image deleted successfully!`);
                console.log(`Image deleted successfully: ${imageUrl}`);
            } catch (error) {
                console.error(`Error deleting image:`, error);
                setErrorMessage("Failed to delete the image. Please try again.");
            } finally {
                setDeleting(false);
            }
        } else {
            setFiles((prev) => ({
                ...prev,
                [field]: prev[field].filter((_, i) => i !== index),
            }));
        }
    };

    const handleImageUpload = async () => {
        const uploadTasks = [];

        Object.entries(files).forEach(([field, fileList]) => {

            if (fileList && Array.isArray(fileList) && fileList.length > 0) {
                fileList.forEach((file) => {
                    const formData = new FormData();
                    formData.append("file", file);

                    const uploadTask = fetch(`${API_URL}${apiEndpoint}/${titleId}`, {
                        method: "POST",
                        headers: {
                            'X-XSRF-TOKEN': csrfToken,
                        },
                        credentials: 'include',
                        body: formData,
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(`Failed to upload image: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then((data) => {
                            console.log(`Image uploaded successfully:`, data.message);
                            setSuccessMessage('Image(s) uploaded successfully!')
                        })
                        .catch((error) => {
                            console.error(`Error uploading image:`, error);
                            setErrorMessage('There was an error uploading the image(s). Please try again later.')
                        });
                    uploadTasks.push(uploadTask);
                });
            } else {
                console.warn(`No files to upload for field: ${field}`);
            }
        });

        setUploading(true);

        try {
            await Promise.all(uploadTasks);
        } catch (error) {
            alert("One or more images failed to upload. Please check the console for details.");
        } finally {
            setUploading(false);
            //handleClose();
        }
    };

    const isSaveDisabled = uploading || (
        Object.values(files).every((fileList) => fileList.length === 0) && existingImages.length === 0
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{modalTitle.replace('{title}', title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {successMessage && (
                    <div className="alert alert-info" role="alert">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <>
                        <FileUploadField
                            fieldName="images"
                            label={uploadFieldName || "Upload here!"}
                            description={uploadFieldDescription || "SVG, PNG, or JPG"}
                            files={files.images || []}
                            onFileChange={handleFileChange}
                            onRemoveFile={(field, index) => handleRemoveFile(field, index)}
                            existingImages={existingImages}
                            onRemoveExistingImage={(index) => handleRemoveFile(null, index, true)}
                            deleting={deleting}
                        />
                        {/* <div className="existing-images mt-3">
                            {existingImages.length > 0 ? (
                                existingImages.map((image, index) => (
                                    <div key={index} className="existing-image-item">
                                        <a href={image} target="_blank" rel="noopener noreferrer">
                                            {image.split('/').pop()}
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </div> */}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={uploading}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleImageUpload} disabled={isSaveDisabled}>
                    {uploading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadImagesModal;

import { useState, useEffect } from 'react';
import { Alert, Table, Modal, Button, Spinner } from 'react-bootstrap';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import EditAuthClientModal from '../EditAuthClientModal/EditAuthClientModal';
import { apiRequest } from '../../utils/apiUtils';
import './ViewAuthClientsModal.scss';

function ViewAuthClientsModal({ show, handleClose, title, titleId }) {
    const [authClients, setAuthClients] = useState([]);
    const [fetchAuthClientsError, setFetchAuthClientsError] = useState(false);
    const [showEditAuthClientModal, setShowEditAuthClientModal] = useState(false);
    const [showDeleteAuthClientModal, setShowDeleteAuthClientModal] = useState(false);
    const [authClientToDelete, setAuthClientToDelete] = useState(null);
    const [authClientToEdit, setAuthClientToEdit] = useState(null);
    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAuthClients = async () => {
            setLoading(true);
            try {
                const data = await apiRequest(`${API_URL}/v1/admin/authclients/${titleId}`, 'GET', csrfToken);
                setAuthClients(data);
            } catch (error) {
                setFetchAuthClientsError(true);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchAuthClients();
        }
    }, [show, titleId, csrfToken, API_URL]);

    const handleEditAuthClient = (authClient) => {
        setShowEditAuthClientModal(true);
        setAuthClientToEdit(authClient);
    }

    const handleDeleteAuthClient = (authClient) => {
        setAuthClientToDelete(authClient);
        setShowDeleteAuthClientModal(true);
    }

    const handleConfirmDelete = async () => {
        if (!authClientToDelete) return;
        const authClientToDeletePk = authClientToDelete.id.split('_')[1];
        try {
            const response = await fetch(`${API_URL}/v1/admin/authclients/${authClientToDeletePk}/${authClientToDelete.sk}`, {
                method: 'DELETE',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to delete auth client');
            }
            // Remove the deleted auth client from the state
            setAuthClients(authClients.filter(client => client.id !== authClientToDelete.id));
            setShowDeleteAuthClientModal(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSuccessSave = (updatedClient) => {
        setAuthClients(prevClients =>
            prevClients.map(client =>
                client.pk.includes(updatedClient.pk) ? updatedClient : client
            )
        );
        setShowEditAuthClientModal(false);
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Auth Clients for {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    {loading ? (
                        <Spinner animation="border" role="status" data-testid="loading-spinner">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <>
                            {fetchAuthClientsError && (
                                <Alert variant="danger">
                                    There was an error fetching Auth Clients. Please try later.
                                </Alert>
                            )}
                            {authClients.length === 0 && !fetchAuthClientsError && (
                                <Alert variant="info">No auth clients available. Configure an Auth Client to view it here.</Alert>
                            )}
                            {authClients.length > 0 && (
                                <div class="table-responsive">
                                    <Table striped borderless hover className="table-fixed-actions">
                                        <thead>
                                            <tr>
                                                <th>Client ID</th>
                                                <th>Client Name</th>
                                                <th>Client Secret</th>
                                                <th>Redirect URIs</th>
                                                <th>Post logout redirect URIs</th>
                                                <th className="action-column">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {authClients.map((authClient) => (
                                                <tr key={authClient.id}>
                                                    <td>{authClient.clientId}</td>
                                                    <td>{authClient.clientName}</td>
                                                    <td>***</td>
                                                    <td>{authClient.redirectUris}</td>
                                                    <td>{authClient.postLogoutRedirectUris}</td>
                                                    <td className="action-column">
                                                        <div style={{ display: 'flex', gap: '1px' }}>
                                                            <Button variant="none" onClick={() => handleEditAuthClient(authClient)}>
                                                                <i className="bi bi-pencil-square"></i>
                                                            </Button>
                                                            <Button variant="none" onClick={() => handleDeleteAuthClient(authClient)}>
                                                                <i className="bi bi-trash3"></i>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>

            <ConfirmDeleteModal
                show={showDeleteAuthClientModal}
                handleClose={() => setShowDeleteAuthClientModal(false)}
                handleConfirm={handleConfirmDelete}
            />
            <EditAuthClientModal
                show={showEditAuthClientModal}
                handleClose={() => setShowEditAuthClientModal(false)}
                titleId={titleId}
                authClient={authClientToEdit}
                onSuccessSave={handleSuccessSave}
            />
        </>
    );
}

export default ViewAuthClientsModal;

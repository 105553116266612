import React from 'react';
import { Pagination } from 'react-bootstrap';

export const renderPagination = (currentPage, totalPages, paginate) => {
    let items = [];
    let startPage, endPage;
    if (totalPages <= 3) {
        startPage = 1;
        endPage = totalPages;
    } else if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
    } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 2;
        endPage = totalPages;
    } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
    }

    if (startPage > 1) {
        items.push(
            <Pagination.First key="first" onClick={() => paginate(1)} />
        );
        items.push(
            <Pagination.Prev key="prev" onClick={() => paginate(currentPage - 1)} />
        );
    }

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    if (endPage < totalPages) {
        items.push(
            <Pagination.Next key="next" onClick={() => paginate(currentPage + 1)} />
        );
        items.push(
            <Pagination.Last key="last" onClick={() => paginate(totalPages)} />
        );
    }

    return (
        <Pagination className="justify-content-center">
            {items}
        </Pagination>
    );
};

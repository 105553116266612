import React, { useState, useEffect } from 'react';
import { Container, Alert, Spinner, Toast } from 'react-bootstrap';
import SelectCountriesModal from '../SelectCountriesModal/SelectCountriesModal';
import ConfigureAuthClientModal from '../ConfigureAuthClientModal/ConfigureAuthClientModal';
import GameOptionsModal from '../GameOptionsModal/GameOptionsModal';
//import UploadModal from '../UploadModal/UploadModal';
import TitlesList from '../TitlesList/TitlesList';
//import useAuth from '../../hooks/useAuth';
import ViewAuthClientsModal from '../ViewAuthClientsModal/ViewAuthClientsModal';

function Titles() {
    const [titles, setTitles] = useState([]);
    const [showCountriesModal, setShowCountriesModal] = useState(false);
    const [showAuthClientModal, setShowAuthClientModal] = useState(false);
    const [showViewAuthClientsModal, setShowViewAuthClientsModal] = useState(false);
    const [showGameOptionsModal, setShowGameOptionsModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAuthClientToast, setShowAuthClientToast] = useState(false);
    const [toastMessageForAuthClient, setToastMessageForAuthClient] = useState('');
    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // can be moved into api directory
        const fetchTitles = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/v1/admin/titles`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                // Check for unauthorized status and HTML response
                if (response.status === 403 || response.headers.get('content-type').includes('text/html')) {
                    console.error('Unauthorized access or HTML response received');

                    // Read the response as text to determine if it's an HTML redirect
                    const responseBodyText = await response.text();
                    console.log('Response body text:', responseBodyText);

                    // If the response contains an HTML form for SAML, extract the action URL and perform a redirect
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(responseBodyText, 'text/html');
                    const form = doc.querySelector('form');

                    if (form && form.action) {
                        window.location.href = form.action;
                        return;
                    } else {
                        throw new Error('Unauthorized access or unexpected HTML response');
                    }
                }

                if (!response.ok) {
                    console.error('Response not OK - status:', response.status);
                    window.location.href = "/";
                    throw new Error(`API call failed with status ${response.status}`);
                }

                try {
                    const data = await response.json();
                    console.log('Fetched titles:', data);
                    console.log('Version test');
                    setTitles(data);
                } catch (jsonError) {
                    console.error('Error parsing response as JSON:', jsonError);
                    throw new Error('Failed to parse JSON response');
                }
            } catch (error) {
                console.error('Error fetching titles:', error);
                setError('Unable to fetch titles. Please try again');
            } finally {
                setLoading(false);
            }
        };

        setLoading(true);
        fetchTitles();
    }, []);

    const handleCloseModal = () => {
        setShowCountriesModal(false);
        setShowAuthClientModal(false);
        setShowViewAuthClientsModal(false);
        setShowGameOptionsModal(false);
        setSelectedTitle(null);
    };

    const handleShowCountriesModal = (title) => {
        setSelectedTitle(title);
        setShowCountriesModal(true);
    };

    const handleShowAuthClientModal = (title) => {
        setSelectedTitle(title);
        setShowAuthClientModal(true);
    };

    const handleShowViewAuthClientModal = (title) => {
        setSelectedTitle(title);
        setShowViewAuthClientsModal(true);
    };

    const handleShowGameOptionsModal = (title) => {
        setSelectedTitle(title);
        setShowGameOptionsModal(true);
    }

    const handleSuccessSaveAuthClientSettings = (message) => {
        setToastMessageForAuthClient(message);
        setShowAuthClientToast(true);
        setTimeout(() => {
            setShowAuthClientToast(false);
        }, 3000); // Clearing the message after 3 seconds
    };

    const renderSpinner = () => (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );

    return (
        <Container className="mt-5">
            <h1>Title List</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading
                ? renderSpinner()
                : <TitlesList
                    titles={titles}
                    handleShowCountriesModal={handleShowCountriesModal}
                    handleShowAuthClientModal={handleShowAuthClientModal}
                    handleShowViewAuthClientModal={handleShowViewAuthClientModal}
                    handleShowGameOptionsModal={handleShowGameOptionsModal}
                />
            }

            {selectedTitle && (
                <>
                    <SelectCountriesModal
                        show={showCountriesModal}
                        handleClose={handleCloseModal}
                        title={selectedTitle.title}
                        titleId={selectedTitle.titleId}
                    />
                    <ConfigureAuthClientModal
                        show={showAuthClientModal}
                        handleClose={handleCloseModal}
                        title={selectedTitle.title}
                        titleId={selectedTitle.titleId}
                        onSuccessSave={handleSuccessSaveAuthClientSettings}
                    />
                    <ViewAuthClientsModal
                        show={showViewAuthClientsModal}
                        handleClose={handleCloseModal}
                        title={selectedTitle.title}
                        titleId={selectedTitle.titleId} />
                    <GameOptionsModal
                        show={showGameOptionsModal}
                        handleClose={handleCloseModal}
                        title={selectedTitle.title}
                        titleId={selectedTitle.titleId}
                    />
                </>
            )}

            <Toast onClose={() => setShowAuthClientToast(false)} show={showAuthClientToast} delay={3000} autohide bg='dark' style={{ position: 'fixed', top: '20px', right: '20px', color: 'white' }}>
                <Toast.Body>{toastMessageForAuthClient}</Toast.Body>
            </Toast>
        </Container>
    );
}

export default Titles;